footer{
  padding: 50px 30px;
  background-color: $footer-background-color;
  color: $footer-color;
  line-height: 22px;
  position: relative;
  a{
    transition: color .2s ease-in;
    color: $footer-color;
    &:hover{
      color: #fff;
    }
  }
  img{
    width: 100%;
    max-height: 120px;
  }
  h2{
    @extend .h4;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: $screen-size-sm){
    .pure-u-1{
      padding: 15px 0;
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
  .menu-seperator{
    ul{
      li{
        a{
          display: block;
          padding: 5px 0;
          border-top: 1px solid $navigation-seperator-color;
          @media (min-width: $screen-size-sm) {
            padding: 0;
            border: 0;
          }
        }
        &:last-child{
          border-bottom: 1px solid $navigation-seperator-color;
          @media (min-width: $screen-size-sm) {
            border: 0;
          }
        }
      }
    }
  }
  .footer-menu{
    ul{
      li{
        a{
          @media (min-width: $screen-size-sm) {
            padding: 0 10px;
            a{
              font-size: 22px;
            }
          }
        }
      }
    }
    &.footer-menu--right{
      text-align: center;
      @media (min-width: $screen-size-sm) {
        text-align: right;
      }
      ul{
        li{
          display: block;
        }
      }
    }
  }
  .footer--social-menu{
    text-align: center;
    @media (min-width: $screen-size-sm) {
      text-align: right;
    }
  }
  .footer--copyright{
    text-align: center;
    @media (min-width: $screen-size-sm) {
      text-align: left;
    }
  }
  .footer--social-menu{
    &.pure-u-1{
      padding-top: 0;
    }
    ul{
      li{
        display: inline-block;
        padding: 20px 30px;
        a{
          .icon-kununu{
            height: 1em;
            vertical-align: -.117em;
          }
          font-size: 24px;
        }
        &:first-child{
          a{
            padding-left: 0;
          }
        }
        @media (min-width: $screen-size-sm) {
          padding: 0 10px;
          a{
            font-size: 22px;
          }
        }
      }
    }
  }
  .footer--copyright{
    margin-top: 20px;
    font-size: 12px;
    line-height: 15px;
  }
  .footer-row{
    display: flex;
    flex-direction: column;
    @media(min-width: $screen-size-md){
      flex-direction: row;
    }
    .footer-col{
      flex: 1;
      padding: 0 5px;
    }
  }
}